<template>
  <el-card>

    <aside>
      <el-row :gutter="10">
        <el-col
          :xs="24"
          :sm="10"
          :md="10"
        >
          <el-select
            v-model="selectedClient"
            value-key="id"
            placeholder="Select Client"
            style="width: 100%;"
            @input="fetchBusinessUnits()"
          >
            <el-option
              v-for="(client, index) in clients"
              :key="index"
              :value="client"
              :label="client.name"
            />
          </el-select>
        </el-col>
      </el-row>
    </aside>
    <b-tabs
      v-if="selectedClient !== ''"
      content-class="mt-1"
      pills
    >
      <b-tab
        lazy
      >
        <template #title>
          <span>ISMS Risk Assessment</span>
        </template>
        <div v-if="selectedClient !== ''">
          <b-tabs
            content-class="mt-1"
          >
            <b-tab
              lazy
            >
              <template #title>
                <feather-icon icon="ListIcon" />
                <span>Risk Assessment Details</span>
              </template>
              <view-risk-assessment
                :selected-client="selectedClient"
                :standard-id="0"
              />
            </b-tab>
            <b-tab
              lazy
            >
              <template #title>
                <feather-icon icon="EyeIcon" />
                <span>Risk Assessment Summary</span>
              </template>
              <risk-assessment-summary
                :data="summary"
                :selected-client="selectedClient"
              />
            </b-tab>
          </b-tabs>
        </div>
      </b-tab>
      <b-tab
        lazy
      >
        <template #title>
          <span>DPIA</span>
        </template>
        <d-p-i-a
          :selected-client="selectedClient"
          :standard-id="0"
        />
      </b-tab>
      <b-tab
        lazy
      >
        <template #title>
          <span>BIA</span>
        </template>
        <div v-if="selectedClient !== ''">
          <el-select
            v-model="selectedBusinessUnit"
            placeholder="Select Business Unit"
            value-key="id"
            style="width: 50%;"
            filterable
          >
            <el-option
              v-for="(business_unit, index) in business_units"
              :key="index"
              :value="business_unit"
              :label="business_unit.unit_name"
            />
          </el-select>
          <p />
          <b-i-a
            v-if="selectedBusinessUnit !== ''"
            :client-id="selectedClient.id"
            :business-unit-id="selectedBusinessUnit.id"
          />
        </div>
      </b-tab>
      <b-tab
        lazy
      >
        <template #title>
          <span>RCSA</span>
        </template>
        <div v-if="selectedClient !== ''">
          <r-c-s-a
            :selected-client="selectedClient"
          />
        </div>
      </b-tab>
    </b-tabs>
  </el-card>
</template>

<script>
import {
  BTabs, BTab,
} from 'bootstrap-vue'
import checkPermission from '@/utils/permission'
// import Setup from './partials/Setup.vue'
// import Assessment from './partials/Assessment.vue'
import ViewRiskAssessment from './ViewRiskAssessment.vue'
import DPIA from '@/views/modules/DPIA/index.vue'
import BIA from '@/views/modules/BIA/risk-assessment/index.vue'
import RCSA from '@/views/modules/RCSA/RiskRegister.vue'
// import RiskRankingMatrix from './RiskRankingMatrix.vue'
import RiskAssessmentSummary from './partials/Summary.vue'
import Resource from '@/api/resource'

export default {
  components: {
    BTabs,
    BTab,
    // Assessment,
    ViewRiskAssessment,
    DPIA,
    BIA,
    RCSA,
    // RiskRankingMatrix,
    RiskAssessmentSummary,
  },
  data() {
    return {
      clients: [],
      business_units: [],
      selectedClient: '',
      selectedBusinessUnit: '',
      matrix: '3x3',
      impacts: [],
      likelihoods: [],
      risk_assessments: [],
      summary: [],
      loading: false,
    }
  },
  created() {
    this.fetchClients()
  },
  methods: {
    checkPermission,
    fetchClients() {
      const app = this
      const fetchClientsResource = new Resource('clients')
      fetchClientsResource.list({ option: 'all' })
        .then(response => {
          app.clients = response.clients
          if (app.clients.length === 1) {
            // eslint-disable-next-line prefer-destructuring
            app.selectedClient = app.clients[0]
            app.fetchBusinessUnits()
          }
        })
    },
    fetchBusinessUnits() {
      const app = this
      const fetchBusinessUnitsResource = new Resource('business-units/fetch-business-units')
      fetchBusinessUnitsResource.list({ client_id: app.selectedClient.id })
        .then(response => {
          app.business_units = response.business_units
          app.loading = false
        }).catch(() => { app.loading = false })
    },
    // fetchRiskAssessments(load = true) {
    //   const app = this
    //   app.loading = load
    //   const fetchRiskAssessmentsResource = new Resource('risk-assessment/fetch-risk_assessments')
    //   fetchRiskAssessmentsResource.list({ client_id: app.selectedClient.id })
    //     .then(response => {
    //       app.risk_assessments = response.risk_assessments
    //       app.loading = false
    //     }).catch(() => { app.loading = false })
    // },
    // fetchRiskAssessmentSummary() {
    //   const app = this
    //   const param = { client_id: app.selectedClient.id }
    //   const fetchConsultingsResource = new Resource('reports/risk-assessment-summary')
    //   fetchConsultingsResource.list(param)
    //     .then(response => {
    //       app.summary = response.summary
    //     })
    // },
  },
}
</script>
